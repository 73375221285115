let bubbleBackground=(id)=>{
// 获取画布对象
let $canvas = document.getElementById(id);
$canvas.width=window.innerWidth;
$canvas.height=window.innerHeight;
let height = $canvas.height;
let width = $canvas.width;
let ctx = $canvas.getContext('2d');

// 兼容语法的 requestAnimFrame
window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame || 
    window.webkitRequestAnimationFrame || 
    window.mozRequestAnimationFrame    || 
    window.oRequestAnimationFrame      || 
    window.msRequestAnimationFrame     ||  
    function( callback ){
    window.setTimeout(callback, 1000 / 60);
  };
})();

// 随机位置创建气泡对象
function bubble() {
  // 随机创建的圆形，半径最小为 10，最大为 64
  let radius = Math.max( 10, Math.random() * 80 );
  
  // 随机位置
  // 左右不可越界
  let x = Math.random() * width;
  x = Math.max(x, radius * 2);
  x = Math.min( x, width - radius);
  
  let y = Math.random() * height;
  y = Math.max(y, radius * 2);
  y = Math.min( y, height - radius);

  return {
    x,
    y,
    sx: Math.random(-0.1, 0.1) || 0.05,
    sy: Math.random(-0.1, 0.1) || 0.05,
    xMin: radius,
    xMax: width - radius,
    yMin: radius,
    yMax: height - radius,
    radius,
    color: `rgba(255, 255, 255, ${ Math.random() * .3 })`,
  };
  
}

// 初始化创建指定数量的圆形
let bubbles = [];
for(let i = 0; i < 30; i++) {
  bubbles.push( bubble() );
}

// 绘制图形并执行动画
function draw() {
  // 清除画布
  ctx.clearRect(0, 0, width, height);
  
  // 遍历处理每个气泡的位置
  bubbles.forEach(function (item) {
    // 绘制气泡
    ctx.beginPath();
    ctx.fillStyle = item.color;
    ctx.arc(item.x, item.y, item.radius* devicePixelRatio, Math.PI * 2, false);
    ctx.fill();
    ctx.strokeStyle = item.color;
    ctx.stroke();
    ctx.closePath();
    
    // 横向移动
    item.x += item.sx/5;
    if( item.x >= item.xMax ){
      item.sx = item.sx * -1;
    }
    if( item.x <= item.xMin){
      item.sx = Math.abs(item.sx);
    }
    
    // 纵向移动
    item.y += item.sy/5;
    if( item.y >= item.yMax ){
      item.sy = item.sy * -1;
    }
    if( item.y <= item.yMin){
      item.sy = Math.abs(item.sy);
    }
    
  });
  
  // 执行动画
  window.requestAnimationFrame(draw);
  
};
return draw();
}
// 开始执行
export default bubbleBackground
